.margin-top-5px {
  margin-top: 5px; }

.margin-bottom-5px {
  margin-bottom: 5px; }

.margin-left-5px {
  margin-left: 5px; }

.margin-right-5px {
  margin-right: 5px; }

.margin-top-10px {
  margin-top: 10px; }

.margin-bottom-10px {
  margin-bottom: 10px; }

.margin-left-10px {
  margin-left: 10px; }

.margin-right-10px {
  margin-right: 10px; }

.margin-top-15px {
  margin-top: 15px; }

.margin-bottom-15px {
  margin-bottom: 15px; }

.margin-left-15px {
  margin-left: 15px; }

.margin-right-15px {
  margin-right: 15px; }

.progress-button {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
  background-size: 1rem 1rem;
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

.rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.confirm-modal {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 12px;

  button {
    border-radius: 5px;
  }

  .btn-cancel {
    margin-right: 20px;
    background: transparent;
    color: #47525E;
    border-radius: 0;
    border-bottom: 2px solid #47525E;
    padding: 0;
  }
  .btn-submit {
    padding-left: 30px;
    padding-right: 30px;
  }
}